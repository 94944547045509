import './App.css';
import logo from './Kreten_Sachverständigenbüro_Digitalanzeige_Website.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Diese Website ist bald für Sie verfügbar!
        </p>
        <img src={ logo } alt='logo' />
      </header>
    </div>
  );
}

export default App;
